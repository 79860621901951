.card {
  margin: 1rem 0px;
  cursor: pointer;
}

.gridContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.8rem 1rem;
}

.title {
  justify-self: end;
  text-align: end;
}
