@import 'css-variables.scss';

$videoImageHeight: 500px;
$videoImageWidth: 900px;

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.galleryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(min(#{$videoImageWidth}, 100%));
  align-items: center;
  position: relative;
}

.videoImageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  height: $videoImageHeight;
  width: 100%;
  max-width: $videoImageWidth;
  margin: auto;
  // border: 2px solid blue;
}

.thumbContainer {
  height: 100px;
  // border: 2px solid blue;
  // display: flex;
  // flex: 1;
}

.thumbItem {
  width: 90px;
  height: 92px;
  border-radius: 4px;
  object-fit: contain;
  padding: 8px;
  margin-top: 4px;
  cursor: pointer;
  // border: 2px solid green;
}

.image {
  max-width: 100%;
  // max-width: $videoImageWidth;
  max-height: $videoImageHeight;
  object-fit: contain;
  // border: 2px solid orange;
}

.arrows {
  position: absolute;
  top: 0px;
  height: calc(#{$videoImageHeight} - 85px);
  display: flex;
  align-items: center;
  // border: 2px solid orange;

  button {
    margin-top: 85px;
    cursor: pointer;
    z-index: 1;
    color: var(--color-green-700);
    font-size: 72px;
    background-color: transparent;
    border: none;

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
    &:enabled:hover {
      transform: scale(1.2);
    }
    &:enabled:active {
      transform: scale(1);
    }
  }

  &[position='left'] {
    left: 0;
  }
  &[position='right'] {
    right: 0;
  }
}

.spinner {
  position: absolute;
  top: 40%;
}

@media (max-width: $screen-breakpoint) {
  .arrows {
    display: none;
  }
}
