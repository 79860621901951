$gutterPadding: 8px;

.table {
  border-collapse: separate;
  border-spacing: 10px;

  tr {
    box-shadow: 0px 1px 0px;
    &:last-child {
      border-bottom: none;
      box-shadow: none;
    }
    th {
      padding-left: 6px;
    }
  }

  thead {
    text-align: left;
  }
}

.productContainer {
  cursor: pointer;
}

.imageContainer {
  width: 100px;
  height: 84px;
  padding: 0px $gutterPadding;
}
.titleContainer {
  min-width: 100px;
  max-width: 500px;
  padding: 0px $gutterPadding;
}
.usernameContainer {
  min-width: 100px;
  max-width: 500px;

  padding: 0px $gutterPadding;
}
.statusContainer {
  min-width: 72px;
  max-width: 100px;
  padding: 0px $gutterPadding;
}

.thumbnail {
  display: block;
  // width: 100%;
  width: 84px;
  height: 84px;
  object-fit: contain;
  padding: 4px 0px;
}
