.container {
  display: flex;
  justify-content: space-around;
}

.cell {
  width: 3.5rem;
  text-align: center;
  font-size: 1.6rem;
  padding: 0.8rem 1.1rem;
}
