.siderContainer {
  @apply overflow-auto;
}

/* width */
.siderContainer::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.siderContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.siderContainer::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  // background: #ebfff3;
}

/* Handle on hover */
.siderContainer::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
