@import 'css-variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  height: 64px;
  background-color: white;

  .header-item {
    display: flex;
    align-items: center;
  }

  img {
    padding: 10px 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: 'pointer';
  }
}

@media (max-width: $screen-breakpoint) {
  .header {
    height: 40px;
    padding: 0px 20px;

    img {
      padding: 8px 0px;
    }
  }
}
