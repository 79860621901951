.container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-items: center;
  padding: 1rem 1rem 0rem 1rem;
  :first-child {
    max-width: 100%;
  }
}

.username {
  font-size: 1.6rem;
  font-weight: 700;
  // overflow-wrap: anywhere;
}

.roleName {
  font-weight: 500;
}

.divider {
  margin: 1rem 0px;
}
