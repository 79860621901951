@import 'css-variables.scss';

.layoutContent {
  flex-grow: 1;
  display: grid;
  padding: 2.4rem;
  margin: 0px;
  min-height: 280px;
  background-color: $body-background;
  grid-auto-rows: min-content;
  overflow-y: auto;
  overflow-y: overlay;
}
