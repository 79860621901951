.container {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1rem 1rem;
  justify-content: center;
  align-items: center;
}

.avatar {
  grid-column: 1/-1;
  justify-self: center;
}

.username {
  grid-column: 1/-1;
  justify-self: center;
}

.itemTitle {
  justify-self: end;
}

.itemContent {
  justify-self: stretch;
}

.radioGroup {
  display: grid;
  grid-template-columns: auto auto;
}

.featuredSwitch {
  width: 44px;
}

.bankList {
  display: block;
}

.featuredCountryContainer {
  min-width: 200px;
  max-width: 300px;
}

.selectContainer {
  width: 100%;
}
