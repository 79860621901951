.container {
  font-size: 16px;
}

.delete {
  color: #ff4d4f;
  padding: 3px 8px 8px 8px;
  cursor: pointer;
  margin-right: 4px;
  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.018);
  }
}

.deleteSpinner {
  color: #ff4d4f;
  padding: 3px 8px 8px 8px;
  margin-right: 4px;
}

.edit {
  color: rgba(0, 0, 0, 0.85);
  padding: 3px 8px 8px 8px;
  margin-left: 4px;
  cursor: pointer;
  &:hover,
  &:active {
    background: rgba(0, 0, 0, 0.018);
  }
}
