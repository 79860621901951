.rich-text-container {
  overflow-y: auto;
  overflow-y: overlay;
  padding-right: 20px;
}

.rich-text-content {
  margin: 10px 0px;
  padding-top: 16px;
  padding-bottom: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;

  .title {
    font-size: 3.4rem;
    font-weight: 500;
    color: white;
    margin: 16px 24px;
    margin-bottom: 32px;
  }

  .rich-text > p,
  h1,
  h2,
  h3 {
    padding: 0px 24px;

    margin-block-start: 0px;
    margin-block-end: 0px;
    // padding-block-start: 1em;
    padding-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: white;
  }
}
