.table {
  justify-self: center;
  border-collapse: separate;
  border-spacing: 0rem 0.5rem;
  min-width: min-content;
  max-width: max-content;

  tr {
    cursor: pointer;
    box-shadow: 0px 1px 0px;
    &:last-child {
      border-bottom: none;
      box-shadow: none;
    }

    td {
      padding-bottom: 0.4rem;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
  }

  thead {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }
}
