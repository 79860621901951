@import 'css-variables.scss';

.layout {
  padding: 0 2.4rem 2.4rem;
}

@media (max-width: $screen-breakpoint) {
  .layout {
    padding: 0;
  }
}
