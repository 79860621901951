@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'render-smooth-image-react/build/style.css';
@import 'css-variables.scss';

// Chrome autofill remove background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// Mozilla autofill remove background color
input {
  filter: none;
}

@media (max-width: $screen-breakpoint) {
  .ant-card-body {
    padding: 24px 5px;
  }

  //sider fix when selecting Mobile users
  .ant-tooltip {
    visibility: hidden;
  }

  .ant-picker-panels {
    flex-direction: column;
  }
}

$colors: (
  bg-green: #dbfff0,
  bg-blue: rgba(186, 218, 255, 0.6),
  bg-pink: #ffecfd,
  bg-caution: #fff2c9,
  bg-grey: #f9f9f9,
  green-300: #00fa96,
  green-700: #18c67f,
  green-800: #006941,
  green-900: #00331e,
  grey-700: #5f6368,
  grey-900: #202124,
  red-secondary: #ff7d7d,
  yellow-secondary: #f2c94c,
  dt-primary: #e6e6e6,
  dt-text-primary: #e6e6e6,
  lt-primary: #000,
  lt-text-primary: #000,
  lt-secondary: #4a4a4a,
  lt-caption: #9aa0a6,
  lt-text-caption: #9aa0a6,
  list-item-hover: #f1f1f1,
  secondary-caution: #ba8c00,
);

:root {
  // each item in color map
  @each $name, $color in $colors {
    --color-#{$name}: #{$color};
  }
}
