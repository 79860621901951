.textContainer {
  max-width: 600px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.8rem 1rem;
}

.tribeDescription {
  max-width: 200px;
}
