@import 'css-variables.scss';

.breadcrumbNavigation {
  padding: 0.8rem 0px;
  // margin: 0px 1.5rem;
}

.breadcrumbItem {
  display: inline-flex;
  max-width: 200px;
}

.breadcrumbLink {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emptyNavigation {
  height: 2.4rem;
  background-color: #f9f9f9;
}

@media (max-width: $screen-breakpoint) {
  .breadcrumbNavigation {
    margin: 0px 1.5rem 0px 3.9rem;
  }
}
