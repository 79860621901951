@import 'css-variables.scss';

.container {
  display: grid;
  column-gap: 3rem;
  row-gap: 2rem;

  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 1rem;
}

.title {
  grid-column: 1/-1;
}

.filterButton {
  justify-self: end;
}

.clearButton {
  justify-self: start;
}

.buttonContainer {
  grid-column: 1/-1;
  display: grid;
  column-gap: 3rem;
  row-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: $screen-breakpoint) {
  .container {
    row-gap: 0rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .buttonContainer {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}
