.table {
  border-collapse: separate;
  border-spacing: 1rem;

  tr {
    cursor: pointer;
    box-shadow: 0px 1px 0px;
    &:last-child {
      border-bottom: none;
      box-shadow: none;
    }

    td {
      padding-bottom: 0.4rem;
    }
  }

  thead {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }
}
