.ant-typography {
  &-blue {
    &-6 {
      @apply text-blue-600;
    }
  }

  &-cyan {
    &-6 {
      @apply text-cyan-600;
    }
  }

  &-flom {
    &-green {
      @apply text-Green-700;
    }
  }

  &-gold {
    &-6 {
      @apply text-yellow-600;
    }
  }

  &-magenta {
    &-8 {
      @apply text-pink-800;
    }
  }

  &-purple {
    &-6 {
      @apply text-purple-600;
    }
  }

  &-pink {
    &-4 {
      @apply text-pink-400;
    }
    &-6 {
      @apply text-pink-600;
    }
  }

  &-link {
    @apply text-Green-700;
  }
}
