@import 'css-variables.scss';

.transferInfoGrid {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 1rem 1rem;
  justify-content: center;
  align-items: center;
}

.itemTitle {
  justify-self: end;
}

.itemContent {
  justify-self: stretch;
}

.code {
  grid-column: 1/-1;
  padding: 0.6rem 0.6rem;
  font-size: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}

@media (max-width: $screen-breakpoint) {
  .transferInfoGrid {
    grid-template-columns: auto auto;
  }
  .itemTitle {
    justify-self: end;
    text-align: end;
  }
}
