.productContainer {
  cursor: pointer;
}

.card {
  margin: 1rem 0px;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  width: 100%;
  height: 180px;
  max-height: 200px;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  // border: 1px solid red;
}

.imageContainer img {
  width: auto;
  height: auto;
  max-width: 100%;
  // max-height: 100%;
  max-height: 180px;
  object-fit: cover;
  background-repeat: no-repeat;
  margin: auto;
}

.textContainer {
  margin: auto;
  max-width: 600px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0.8rem 1rem;
}

.title {
  justify-self: end;
}
