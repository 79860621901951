.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}

.animation {
  width: 100px;
  height: 100px;
}
