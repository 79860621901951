.transferInfoGrid {
  display: grid;
  grid-template-columns: max-content;
  grid-gap: 1rem 1rem;
  justify-content: center;
  align-items: center;
}

.itemTitle {
  justify-self: start;
}

.itemContent {
  justify-self: start;
}

.paymentResponseTitle {
  align-self: flex-start;
}

.code {
  max-width: 450px;
  padding: 0.6rem 0.6rem;
  font-size: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
