$primary-color: #18c67f;
$body-background: #ffffff;
$layout-header-background: #ffffff;
$layout-body-background: #f9f9f9;
$form-item-label-font-size: 16px;

$screen-sm: 576px;

// also change on core/helpers/layout/screenBreakpoint for correct logic response
$screen-breakpoint: $screen-sm;
